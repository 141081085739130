import React, { useEffect, useState } from 'react'
import Header from './Header'
import i18n from 'i18next';
import './css/Portfolio.css'
import { FaSquareInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { IoLogoTiktok } from "react-icons/io5";
import { ToastContainer ,toast} from 'react-toastify';
import Footer from './footer';
import { Link } from 'react-router-dom';

function Portfolio() {

    useEffect(()=>{},[
        window.scroll(0,0)
      ],[])

    const Downloadcv = ()=>{
        toast.warning("CV cannot be downloaded by new members", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    
    useEffect(() => {
      // العثور على العنصر المحدد بالـ class "Product"
      const productDiv = document.querySelector('.Home');
  
      // منع القائمة التي تظهر عند الضغط على الزر الأيمن
      const handleContextMenu = (event) => {
        event.preventDefault(); // منع السلوك الافتراضي
      };
  
      // إضافة الحدث "contextmenu"
      if (productDiv) {
        productDiv.addEventListener('contextmenu', handleContextMenu);
      }
  
      // تنظيف الحدث عند إلغاء التثبيت
      return () => {
        if (productDiv) {
          productDiv.removeEventListener('contextmenu', handleContextMenu);
        }
      };
    }, []);
  
  return (
    <div className='Home'>
        <Header/>
        <ToastContainer/>
        <div className="Home-container">
            <div className="Home-container-text">
            <h2>I'm <span>Soufiane</span> Moutaouakil</h2>
            <p>
            A mural and canvas artist, I specialize in transforming walls into artistic masterpieces that convey diverse stories and emotions. I offer design and execution services for unique murals that reflect the spirit of every space. 🎨✨</p>
            <div className="Home-text-btn">
            <button onClick={Downloadcv} className='bg-primary'>Download Cv</button>
            </div>
            <div className="Home-futter">
                <Link to={'https://www.facebook.com/profile.php?id=100063539628242&mibextid=LQQJ4d'}>
                <h4 className='text-dark'><FaFacebook/></h4>
                </Link>
                <Link to={'https://www.instagram.com/soufiane_svr/profilecard/?igsh=MTVqeGlyenkwcGRzdg=='}>
                <h4 className='text-dark'><FaSquareInstagram/></h4>
                </Link>
                <Link to={'https://www.tiktok.com/@soufiane_svr?_t=8qpBdRYMlCI&_r=1'}>
                <h4 className='text-dark'><IoLogoTiktok/></h4>
                </Link>
            </div>
            </div>
            <div className="Home-container-img">
            <img src="./profile img.png" alt="" /> 
            </div>
        </div>
        <hr />
        <Footer/>
    </div>
  )
}

export default Portfolio