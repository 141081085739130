import React, { useEffect } from 'react';
import './css/page5.css';

const Page5 = () => {


  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.getElementById('page5');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);


  return (
    <div id='page5' className="page-5  container my-5">
      <header className="text-center mb-4">
        <h1 className="display-4">Welcome to Artilo.site</h1>
        <p className="lead">Premium Digital Art by Soufiane Moutaouakil</p>
      </header>

      <section className="mb-5">
        <p>
          Discover exclusive digital artworks backed by authenticity and transparency. Artilo.site is your trusted marketplace for high-quality digital paintings, ideal for collectors and art enthusiasts.
        </p>
        <h3>Why Choose Us?</h3>
        <ul className="list-group mb-4">
          <li className="list-group-item">Original digital paintings by an acclaimed artist.</li>
          <li className="list-group-item">Secure platform trusted by global buyers.</li>
          <li className="list-group-item">High-resolution images with authenticity certificates.</li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Explore Our Key Themes:</h2>
        <div className="row">
          {[
            "Buy digital art online",
            "Affordable digital paintings",
            "High-resolution art downloads",
            "Exclusive works by Soufiane Moutaouakil",
            "Digital gifts for art lovers"
          ].map((keyword, index) => (
            <div className="col-md-6 mb-3" key={index}>
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">{keyword}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <footer className="text-center mt-5">
        <p className="text-muted">
          At Artilo.site, we create a welcoming space for art lovers. Our curated selection reflects our commitment to quality and authenticity, allowing you to explore and enjoy digital art with confidence.
        </p>
      </footer>
    </div>
  );
};

export default Page5;