import React, { useEffect } from 'react';
import Header from './component/Header';
import Footer from './component/footer';

const Services = () => {


  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.querySelector('.services');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  return (
    <>
    <Header/>
    <div className="services container my-5">
      <header className="text-center mb-4">
        <h1 className="display-4">Our Services at Artilo.site</h1>
        <p className="lead">Exceptional Art Experiences Tailored for You</p>
      </header>

      <section className="mb-5">
        <h2>What We Offer</h2>
        <p>
          At Artilo.site, we are committed to delivering a variety of exceptional services to meet the needs of art enthusiasts, collectors, and decorators. Explore our offerings and discover how we can enhance your art experience.
        </p>
      </section>

      <section className="mb-5">
        <h2>Our Key Services</h2>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>Exclusive Digital Art Sales:</strong> Purchase unique digital paintings created by renowned artist Soufiane Moutaouakil, perfect for personal collections or as gifts.
          </li>
          <li className="list-group-item">
            <strong>Custom Art Commission:</strong> Collaborate with Soufiane Moutaouakil to create bespoke artworks that reflect your vision and style.
          </li>
          <li className="list-group-item">
            <strong>Art Consultation Services:</strong> Receive expert advice on selecting the right artwork for your space, whether it's for home décor or office environments.
          </li>
          <li className="list-group-item">
            <strong>Secure Online Transactions:</strong> Enjoy a safe and seamless purchasing experience with our secure payment methods and transparent processes.
          </li>
          <li className="list-group-item">
            <strong>Art Gift Packages:</strong> Discover thoughtful digital art gift options that cater to art lovers, perfect for any occasion.
          </li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Why Choose Our Services?</h2>
        <p>
          Our commitment to quality, authenticity, and customer satisfaction sets us apart. By choosing Artilo.site, you are not only purchasing art; you are investing in a unique experience that celebrates creativity and individuality.
        </p>
      </section>

      <footer className="text-center mt-5">
        <p className="text-muted">
          Explore our services and immerse yourself in the world of exclusive digital art at Artilo.site. Whether you're a seasoned collector or new to the art scene, we have something special for you.
        </p>
      </footer>
    </div>
    <Footer/>
    </>
  );
};

export default Services;
