import React, { useEffect, useState } from 'react'
import Header from './Header'
import './css/addtocart.css'
import { IoMdCloseCircle } from "react-icons/io";
import { PayPalScriptProvider,FUNDING, PayPalButtons } from "@paypal/react-paypal-js";
import Footer from './footer';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading';
import { ToastContainer, toast } from 'react-toastify';

function AddToCartSold({ inpValue,setValue }) {

    const [pay,setPay] = useState(false)
    const [username,setusername] = useState('')
    const [fullname,setFullName] = useState('')
    const [email,setEmail] = useState('')
    const [post,setPost] = useState()
    const [loading,setloading] = useState(false)

    const navigate = useNavigate()

    const {postId} = useParams()

    // get post by id
 useEffect(() => {
  const getPostbyId = async () => {
    setloading(true)
      try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2002/post/${postId ? postId : "67124cbc16cefa734e335518"}`);
          setPost(res.data);
          setloading(false)
        } catch (err) {
          console.log(err);
      }
  };
  getPostbyId();
}, [postId]);

    const subtotal = post?.price * inpValue; // Example value
    const taxes = 0.00; // Example value

    
    const createOrder = (data, actions) => {
      return actions.order.create({
          purchase_units: [{
              amount: {
                  value: total.toFixed(2),
                  currency_code: 'USD'
              }
          }]
      });
  }

  const createOrderMasterCard = (data, actions) => {
      return actions.order.create({
          purchase_units: [{
              amount: {
                  value: total,
                  currency_code: 'USD'
              }
          }],
          application_context: {
            shipping_preference: "NO_SHIPPING", // تعطيل الشحن
            user_action: "PAY_NOW" // التوجيه مباشرة للدفع
          },
              payer: {
               
                address: {
                  country_code: 'MA' // يمكنك تغيير رمز الدولة حسب الحاجة
                },
                email_address: "customer@example.com"
              }
      });
  }

  const paypalOptions = {
    'client-id': 'AVoTvtXe4pe2V7e36ZV2xkYm_XIbXwR0cqTl_Pa4lA7-WsoQBGsL1UOsHJnLwwMIpknJEFpD1b-Nm45f',
    currency: 'USD',
  };


 

    const payPrice = ()=>{

      
      if(username.length < 2){
        toast.error('Please enter a valid username.')
        return
      }
      if(fullname.length < 2){
        toast.error('Please enter a valid full name.')
        return
      }
      if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
        toast.error('Please enter a valid email.')
        return
      }
      setPay(true)
    }

    

    const onApprove =async(data, actions) => {
      try {
        // Capture the payment
        const paymentDetails = await actions.order.capture();
    
        // Check if the payment was successful
        if (paymentDetails.status === 'COMPLETED') {
          toast.success('Your account has been charged successfully');
          await axios.post(`${process.env.REACT_APP_API_URL}/api/v2002/order/671ad59824f46a250192b54b`, {
            total: total,
            username: username,
            fullname: fullname,
            email: email,
            LinkDrive : "https://drive.google.com/drive/folders/1SxHFIOk8Uqurp8JlbIiZqYKQeTqWfk7b?usp=drive_link"
          }).then((res) => {
         navigate(`/order-confirmation/${res.data.order._id}/${res.data.token.token}`);
          });
        } else {
          toast.error('Payment was not completed successfully');
        }
      } catch (error) {
        toast.error('An error occurred while processing the payment');
        console.error('Payment capture error:', error);
      }
    };
    

  
 // tabalue 1 
 const priceTab1 = 29
 const priceTab2Secondqry = 399
 const title = "title tile titile"
 const description = "description description description"
// tablaux 2
 const priceTab2 = 29
 const priceTab2Second = 399
 const titleTab2 = "title tile titile"
 const descriptionTab2 = "description description description"
// tableux 3
 const priceTab3 = 0
 const priceTab3Second = 299
 const titleTab3 = "title tile titile"
 const descriptionTab3 = "description description description"
// totale
const total = 29 + 29;
const priceSecondary = post?.priceSecondary * inpValue



  return (
    <div className='Add-to-cart'>
      <Header/>
      <ToastContainer/>
      <audio id='audio' src="/short-success-sound-glockenspiel-treasure-video-game-6346.mp3" preload="auto" style={{ height: '0px', width: '0px' }}></audio>
      <p id='promo'>This offer is valid for only <span style={{fontWeight:'700'}}> 3 days.</span> </p>
      <div className="Checkout-container-1">
      <h2 className='item mt-3'>Your cart : 3 item {">"} checkout</h2>
      <div className="add-to-cart-container">
      <div className="Checkout-container-left">
        <h6>details order</h6>
        <div className="Checkout-card">
        <div  className="Checkout-container-1 ">
        <h2 className='item'>Your cart : 3 item</h2>
        <div  className="Checkout-card">
            <div  className="Product-cart">
              <img onClick={()=>navigate('/post/'+'671ad59824f46a250192b54b')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729811870/ycavyaqax39mzrbxsgaw.jpg" alt="" />
              <div className="Product-cart-title">
              <h2> A Glan...  </h2>
              <p>
              This unique...
              </p>              
            </div>
            </div>
            <div className="input-cart">
                <h6>1</h6>
            </div>
            <div className="price">
              <h3> {priceTab1} $ <span> {priceTab2Secondqry} $ </span> </h3>
            </div>
        </div>
        <div className="Checkout-card">
            <div className="Product-cart">
              <img onClick={()=>navigate('/post/'+'671add4d7a04e5cac4136a71')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813849/nze6ixmx6rffz7ilhx6a.jpg" alt="" />
              <div className="Product-cart-title">
              <h2> Spect... </h2>
              <p>
              Spectrumme ... </p>              
            </div>
            </div>
            <div className="input-cart">
                <h6>1</h6>
            </div>
            <div className="price">
              <h3> {priceTab2} $ <span> {priceTab2Second} $ </span> </h3>
            </div>
        </div>
        <div className="Checkout-card">
            <div className="Product-cart">
              <img onClick={()=>navigate('/post/'+'671adba47a04e5cac4136a5a')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813431/r0usp70or16wty6ls729.jpg" alt="" />
              <div className="Product-cart-title">
              <h2> The Mirro...</h2>
              <p>
              The Mirror o...
              </p>              
            </div>
            </div>
            <div className="input-cart">
                <h6>1</h6>
            </div>
            <div className="price">
              <h3 className='text-success'> free $ <span > {priceTab3Second} $ </span> </h3>
            </div>
        </div>
      </div>
        </div>
       <div className="container-shipping">
      <div className="card-shipping ">
        <h4 className="mb-4">Digital Product Delivery Details</h4>
        <p className="text-muted ">
          The file will be sent to your email once the payment is confirmed.
        </p>

        <form >
          <div className="mb-3">
            <div className="input-name">
            <div className="input-name-left">
            <label  htmlFor="name" className="form-label">
              Full Name
            </label>
            <input
              value={username}
              onChange={(e)=>setusername(e.target.value)}
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your full name"
              required
            />
            </div>
            <div className="input-name-rigth">
            <label htmlFor="name" className="form-label">
              Full Name
            </label>
            <input
            value={fullname}
            onChange={(e)=>setFullName(e.target.value)}
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your full name"
              required
            />
            </div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <input
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email address"
              required
            />
          </div>
          <hr />
        </form>
      </div>
    </div>

      </div>
      <div className="1020.MP3 ">
      <div className="card mb-4 p-4">
        <h2 style={{fontSize:'20px'}} className="text-center mb-4">Order Summary</h2>
        <div className="row mb-3">
          <div style={{fontSize:'15px'}} className="col-6">Subtotal (3 items):</div>
          <div className="col-6 text-right">${total.toFixed(2)}</div>
        </div>
        <div className="row mb-3">
          <div style={{fontSize:'15px'}} className="col-6">Est. taxes & fees:</div>
          <div className="col-6 text-right">${taxes.toFixed(2)}</div>
        </div>
        <div className="row mb-3">
          <div className="col-6"><strong>Total (Digital Product):</strong></div>
          <div className="col-6 text-right text-primary"><strong>${total.toFixed(2)}</strong></div>
        </div>
        <div className="Checkout-container-cart text-center ">
       {!pay &&<button style={{width:'100%'}} onClick={payPrice} className='btn btn-primary text-succes'> Pay</button>}
        {pay && <PayPalScriptProvider options={paypalOptions}>
            <div className="App" style={{width:'350px'}}>
                      <PayPalButtons
                        fundingSource={FUNDING.PAYPAL}
                        createOrder={createOrder}
                        onApprove={onApprove}
                      />
                      <PayPalButtons
                        fundingSource={FUNDING.CARD}
                        createOrder={createOrderMasterCard}
                        onApprove={onApprove}
                      />
            </div>
        </PayPalScriptProvider>}
        </div>
        <p className="text-center text-success mt-3">
          Congrats — you get <strong>free delivery</strong> (digital product)!
        </p>
      </div>
      </div>
      </div>
      </div>
      {/* Add your cart components here */}
    <Footer/>
    {loading && <Loading/>}

    </div>
  )
}

export default AddToCartSold
