import React from 'react'

function Footer() {
  return (
    <div className='text-center p-3' style={{ backgroundColor: 'rgb(233, 233, 233)' }}>
    © 2020 Copyright:
    <a className='text-primary' href='https://Artilo.site/'>
    Artilo.site
    </a>
  </div>
  )
}

export default Footer
