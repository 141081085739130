import React, { useEffect, useState } from 'react'
import { IoIosArrowDown } from "react-icons/io";

function Page2({post}) {

  const [Alldescription,setAllDiscrition] = useState(false)
  
  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.querySelector('.page2');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  return (
    <div style={{margin:'auto '}} className='page2  col-10'>
      <hr />
      <h6 style={{fontWeight:'700',textDecoration:'underline'}} className='text-danger text-center'>Description</h6>
          {!Alldescription && <p style={{fontWeight:"600",color:'rgb(107, 107, 107)',fontSize:'13px'}}>
          {post?.descriptionSeCondary?.length > 800 
                  ? `${post?.descriptionSeCondary.substring(0, 800)}...` 
                  : post?.descriptionSeCondary}
       </p>}
       {Alldescription && <p style={{fontWeight:"600",color:'rgb(107, 107, 107)',fontSize:'13px',textAlign:'center'}}>
          {post?.descriptionSeCondary}
       </p>}
      {!Alldescription && <li onClick={()=>setAllDiscrition(true)} className='text-center' style={{cursor:'pointer',textDecoration:'underline'}} >Read more <span><IoIosArrowDown/></span></li>}
      {Alldescription && <li onClick={()=>setAllDiscrition(false)} className='text-center' style={{cursor:'pointer',textDecoration:'underline'}} >Read less <span ><IoIosArrowDown/></span></li>}
      <hr />
    </div>

  )
}

export default Page2
