import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa'; // Success icon
import { MdShoppingBasket } from 'react-icons/md'; // Shopping cart icon
import Header from './Header';
import './css/OrderConfirmation.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import NotFoundPage from './NotFound';
import Loading from '../Loading';

function OrderConfirmation() {
  

  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.querySelector('.OrderConfirmation');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);




  const navigate = useNavigate()
  // Variables
  const priceTab1 = 35;
  const priceTab2Secondqry = 50;
  const priceTab2 = 35;
  const priceTab2Second = 200;
  const {orderId} = useParams()
  const {token} = useParams()
  const [orderConferm,setOredrConferm] = useState(false)
  const [notFound,setNotFound] = useState(false)
  const [loading,setloading] = useState(false)
  useEffect(()=>{
    setloading(true)
    const getToken =async( )=>{
      await axios.put(`${process.env.REACT_APP_API_URL}/api/v2002/order/${orderId}/${token}`)
      .then((res)=>{
        toast.error(res.data.messagv)
        if(res.data.messagv){
          setOredrConferm(false)
          setNotFound(true)
        }
        toast.success(res.data.message)
        if(res.data.message){
          setOredrConferm(true)
        }
        setloading(false)
      })
    }
    getToken()
  },[])

  return (
    <div className="OrderConfirmation">
      <Header />
        <ToastContainer/>
      <div className="OrderConfirmation-container mt-5">
        <div className="OrderConfirmation-row row justify-content-center">
          {/* Success Message */}
       
          <div className="OrderConfirmation-text col-md-8 text-center">
          <FaCheckCircle size={80} className="text-success" />
          <h2 className="text-success">Operation Successful</h2>
          <p className="text-muted">
            Your purchase has been completed successfully. The transaction details and a download link for your product will be sent to your email.
          </p>
          <p className="text-muted">
            If you do not find the link in your inbox, please check your spam or junk folder.
          </p>
          <p>
            <span className='text-danger'>**Warning:**</span> This file is for personal use only and may not be used for commercial purposes.
          </p>
        </div>
          {/* Shopping Cart */}
          <div className="text-center d-flex flex-column align-items-center justify-content-center ">
      
      <div className="mt-4">
        <button onClick={()=>navigate('/post/')} variant="primary" className=" btn btn-primary me-2">
          RETURN HOME
        </button>
        <button onClick={()=>navigate('/contact')} className='btn' style={{border:'1px solid blue'}} variant="outline-secondary">REPORT PROBLEM</button>
      </div>
    </div>
        </div>
        
      </div>
      
      {loading && <Loading/>}
    </div>
  );
}

export default OrderConfirmation;
