import React, { useEffect } from 'react'
import Page4 from './Page4'
import Header from './component/Header'
import Footer from './component/footer'
import './css/page5.css'
function Contact() {

    useEffect(()=>{},[
        window.scroll(0,0)
      ],[])

  return (
    <div className='contact-page'>
        <Header/>
      <Page4/>
      <Footer/>
    </div>
  )
}

export default Contact
