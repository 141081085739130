import React, { useState } from 'react'
import './css/barEmail.css'
import { FaFilePdf } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import axios from 'axios';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner'

function BarEmail({urlFile,serBarEmail}) {
    
    const [loading,setloading] = useState(false)
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')


    const request = async(e)=>{
        e.preventDefault()
        setloading(true)
        if(!username ||!email){
            toast.error("Please fill all fields")
            setloading(false)
            return
        }
        // التحقق من أن البريد الإلكتروني يحتوي على "@gmail.com"
          if (!email.includes("@gmail.com")) {
            toast.error("Please enter a valid Gmail address");
            setloading(false)
            return;
          }
        await axios.post(`${process.env.REACT_APP_API_URL}/api/v2002/freefile`,{
            username,
            email,
            linkFreefile : urlFile
        }).then((res)=>{
            setloading(false)
            setUsername('')
            setEmail('')
            toast.success(res.data.message)
        })
    }

  return (
    <div className='barEmail'>
    <div className="bar-email-container">
        <h4 onClick={()=>serBarEmail(false)} className='close-bar-email'><IoIosCloseCircle/></h4>
        <h3 className='text-danger text-center' style={{fontSize:'34px'}}> <FaFilePdf/></h3>
    <h2> 
    To request the free file, please enter the following information to receive the download link via your email.</h2>
    <form action="">
    <h6>username</h6>
    <input value={username} onChange={(e)=>setUsername(e.target.value)} type="text" placeholder='username' />
    <h6>your email</h6>
    <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder='email'/>
<button onClick={request} className='btn btn-primary'> {loading ? <ColorRing
  visible={true}
  height="30"
  width="30"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={['#fefe', '#fefe', '#fefe', '#fefe', '#fefe']}
  />  : "Request the file"} </button>
    </form> 
    </div>
    </div>
  )
}

export default BarEmail
