import React, { useEffect, useState } from 'react'
import Header from './component/Header'
import Product from './component/Product'
import './css/home.css'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import SoldPub from './component/soldPub'
import Page4 from './Page4'
import Checkout from './component/Checkout'
import Video from './component/Video'
import axios from 'axios'
import Footer from './component/footer'
import Loading from './Loading'
import CheckoutSold from './component/CheckoutSold'
import { useParams } from 'react-router-dom'
import Page5 from './Page5'
import BarEmail from './component/barEmail'

function Home({inpValue,setValue ,post,setPost}) {


  const [sidibarCart,setSidibarCart] = useState(false)
  const [sidibarSold,setsidibarSold] = useState(false)
  const [video,setVideo] = useState(false)
  const [soldPub,setSoldPub] = useState(false)
  const [posts,setPosts] = useState([])
  const [imgSrc,setImgSrc] = useState('')
  const [loading,setloading] = useState(true)
  const [BarEmailcard,serBarEmail] = useState(false)
  const [urlFile,setUrlfile] = useState('')
  const [vidoeurl,setVideourl] = useState('')
  const {postId} = useParams()

    useEffect(()=>{
        const getAllPosts = async()=>{
            await axios.get(`${process.env.REACT_APP_API_URL}/api/v2002/posts`)
            .then((res)=>{
                setPosts(res.data)
            }).catch((err)=>{
                console.log(err);
            })
        }
        getAllPosts()
    },[])

 // get post by id
 useEffect(() => {
  setValue(1)
  setPost("")
  const getPostbyId = async () => {
    setloading(true)
      try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2002/post/${postId ? postId : "671ad59824f46a250192b54b"}`);
          setPost(res.data);
          setImgSrc(res.data.images[0].imagePrincipale?.url)
          setloading(false)
        } catch (err) {
          console.log(err);
      }
  };
  getPostbyId();
}, [postId]);
      
    useEffect(()=>{
        setTimeout(()=>{
          setSoldPub(true)
        },2000)
    },[])

    useEffect(()=>{
      const home = document.getElementById('home')
    home.onmouseout =()=>{
    }
    })
  
    useEffect(()=>{},[
      window.scroll(0,0)
    ],[postId,posts])

    useEffect(() => {
      // العثور على العنصر المحدد بالـ class "Product"
      const productDiv = document.querySelector('.home');
  
      // منع القائمة التي تظهر عند الضغط على الزر الأيمن
      const handleContextMenu = (event) => {
        event.preventDefault(); // منع السلوك الافتراضي
      };
  
      // إضافة الحدث "contextmenu"
      if (productDiv) {
        productDiv.addEventListener('contextmenu', handleContextMenu);
      }
  
      // تنظيف الحدث عند إلغاء التثبيت
      return () => {
        if (productDiv) {
          productDiv.removeEventListener('contextmenu', handleContextMenu);
        }
      };
    }, []);

  return (
    <div id='home' className='home col-12'>
       <Header/>
       <p id='promo'>This offer is valid for only <span style={{fontWeight:'700'}}> 3 days.</span> </p>
       <Page1 setVideourl={setVideourl} setUrlfile={setUrlfile} serBarEmail={serBarEmail} setsidibarSold={setsidibarSold} imgSrc={imgSrc} setImgSrc={setImgSrc} setValue={setValue} inpValue={inpValue} post={post} setvideo={setVideo} postId={postId} setSidibarCart={setSidibarCart} />
        <Page2 post={post} />
        <Page3  posts={posts}/>
        <h2 id='contactus'>contact us</h2>
        <Page4/>
        <Page5/>
        <hr />
   
        {video && <Video setVideo={setVideo} vidoeurl={vidoeurl} />}
        {soldPub && <SoldPub setsidibarSold={setsidibarSold} setSidibarCart={setSidibarCart} />}
        {sidibarCart && <Checkout setValue={setValue} inpValue={inpValue}  post={post} setSidibarCart={setSidibarCart}/>}
        {sidibarSold && <CheckoutSold  setSidibarCart={setsidibarSold}/>}
        <Footer/>
       {loading && <Loading/>}
       {BarEmailcard && <BarEmail urlFile={urlFile} serBarEmail={serBarEmail} />}
    </div>
  )

}

export default Home
