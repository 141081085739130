import React, { useEffect, useState } from 'react'
import './css/Header.css'
import {Link} from 'react-router-dom'
import { MdDoubleArrow } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";

function Header() {

  const [header,setHeader] = useState(false)
  const [headerBtn,setHeaderBtn] = useState(false)

  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.querySelector('.Header');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  return (
    <div className='Header col-12'>
      <div className="Header-container ">
          <div className="Header-container-logo">
            <Link to={'/post'} >
            <img src="http://res.cloudinary.com/dvivzto6g/image/upload/v1729546488/ohshch6uy7iqi9dd96v5.png" alt="" />
            </Link>
          </div>
          <div className="Header-container-service">
            <Link to={'/services'} style={{textDecoration:'none'}}>
            <h6 style={{borderLeft:'none'}}>services</h6>
            </Link>
            <Link  to={'/about'} style={{textDecoration:'none'}}>
            <h6>about</h6>
            </Link>
            <Link to={'/contact'}  style={{textDecoration:'none'}}>
            <h6>contact</h6>
            </Link>
            <Link to={'/'} style={{textDecoration:'none'}}>
            <h6 > primary & home</h6>
            </Link>
           {!headerBtn ? <a onClick={()=>setHeader(true)+setHeaderBtn(true)} style={{cursor:'pointer'}}  className=' arrow-header'><MdDoubleArrow style={{rotate:'90deg'}} /></a> : <a onClick={()=>setHeader(false)+setHeaderBtn(false)} style={{cursor:'pointer'}}  className=' arrow-header'><IoCloseSharp  style={{rotate:'90deg'}} /></a>}
          </div>
      </div>
     {header &&  <div className="Header-container-bottom">
           <Link to={'/post'} style={{textDecoration:'none'}}>
            <h6 style={{borderLeft:'none'}}>home</h6>
            </Link>
           <Link to={'/services'} style={{textDecoration:'none'}}>
            <h6 style={{borderLeft:'none'}}>services</h6>
            </Link>
            <Link  to={'/about'} style={{textDecoration:'none'}}>
            <h6>about</h6>
            </Link>
            <Link to={'/contact'}  style={{textDecoration:'none'}}>
            <h6>contact</h6>
            </Link>
            <Link to={'/'} style={{textDecoration:'none'}}>
            <h6 > primary & home</h6>
            </Link>
     </div>}
    </div>
  )
}

export default Header
