import React, { useState } from 'react'
import './css/CreatePost.css'
import axios from 'axios'
import { RotatingLines } from 'react-loader-spinner'
import Header from '../component/Header';

function CreatePost() {
    const [files, setFiles] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionSecondary, setDescriptionSecondary] = useState('');
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState(1);
    const [priceSecondary, setPriceSecondary] = useState(1);
    const [sales, setsales] = useState(1);
    const [rest, setRest] = useState(1);
    const [totalOrder, setTotalOrder] = useState(1);
    const [videoUrl, setvideoUrl] = useState("");
    const [loading,setLoading] = useState(false)
    const [LinkDrive,setLinkDrive] = useState(false)
    const [FreeLinkDrive,setFreeLinkDrive] = useState(false)
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length !== 7) {
            console.log("Please select exactly 7 images.");
        } else {
            setFiles(selectedFiles);
        }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true)
      if (files.length !== 7) {
        setLoading(false)
        return console.log("Please upload exactly 7 images.");
    }

    try {
        // إنشاء منشور جديد
        const postResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/v2002/post`, {
            title,
            description,
            descriptionSeCondary: descriptionSecondary,
            category,
            price,
            priceSecondary,
            sales,
            theRest: rest,
            totaleOrders: totalOrder,
            videoUrl,
            LinkDrive : LinkDrive,
            FreeLinkDrive : FreeLinkDrive
        });

        const postId = postResponse.data._id; // افتراضًا أن المنشور الجديد يتم إنشاؤه ويُعاد مع _id
        
        // رفع الصور بعد نجاح إنشاء المنشور
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('images', file); // استخدام 'images' كاسم موحد للصور
        });

        await axios.post(`${process.env.REACT_APP_API_URL}/api/v2002/post/img/${postId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        setLoading(false)
        alert("Post and images uploaded successfully.");
        console.log("Post and images uploaded successfully.");
    } catch (error) {
        console.error("Error uploading post or images:", error);
    }
  };
    
    return (
        <div className='create-post'>
          <Header/>
            <form onSubmit={handleSubmit}>
            <input 
    type="file" 
    multiple 
    accept="image/*" 
    name="images" // تأكد من أن الاسم هو "images"
    onChange={handleFileChange} 
/>
                <div className="images">
                    {files.length > 0 && files.map((file, index) => (
                        <img 
                            key={index} 
                            src={URL.createObjectURL(file)} 
                            alt={`Selected ${index + 1}`} 
                            width="100" 
                        />
                    ))}
                </div>
                <h6>title</h6>
                <input 
                    type="text" 
                    placeholder='title' 
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <h6>descriptionSecondary</h6>
                <input 
                    type="text" 
                    placeholder='description' 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <h6>description</h6>
                <input 
                    type="text" 
                    placeholder='descriptionSecondary' 
                    value={descriptionSecondary}
                    onChange={(e) => setDescriptionSecondary(e.target.value)}
                />
                <h6>category</h6>
                <input 
                    type="text" 
                    placeholder='category' 
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                />
                <h6>LinkDrive</h6>
                <input 
                    type="text" 
                    placeholder='category' 
                    value={LinkDrive}
                    onChange={(e) => setLinkDrive(e.target.value)}
                />
                <h6>FreeLinkDrive</h6>
                <input 
                    type="text" 
                    placeholder='category' 
                    value={FreeLinkDrive}
                    onChange={(e) => setFreeLinkDrive(e.target.value)}
                />
                <h6>price</h6>
                <input 
                    type="number" 
                    placeholder='price' 
                    value={price}
                    onChange={(e) => setPrice(Number(e.target.value))}
                />
                <h6>priceSecondary</h6>
                <input 
                    type="number" 
                    placeholder='priceSecondary' 
                    value={priceSecondary}
                    onChange={(e) => setPriceSecondary(Number(e.target.value))}
                />
                <h6>sales</h6>
                <input 
                    type="number" 
                    placeholder='sales' 
                    value={sales}
                    onChange={(e) => setsales(Number(e.target.value))}
                />
                <h6>the rest</h6>
                <input 
                    type="number" 
                    placeholder='the rest' 
                    value={rest}
                    onChange={(e) => setRest(Number(e.target.value))}
                />
                <h6>total order</h6>
                <input 
                    type="number" 
                    placeholder='total order' 
                    value={totalOrder}
                    onChange={(e) => setTotalOrder(Number(e.target.value))}
                />
                <h6>video</h6>
                <input 
                    type="text" 
                    placeholder='video url' 
                    value={videoUrl}
                    onChange={(e) => setvideoUrl(e.target.value)}
                />
                <button type="submit"> {loading ? <RotatingLines
  visible={true}
  height="36"
  width="36"
  color="black"
  strokeWidth="5"
  animationDuration="0.75"
  ariaLabel="rotating-lines-loading"
  wrapperStyle={{}}
  wrapperClass=""
  /> : "Create"}</button>
            </form>
        </div>
    )
}

export default CreatePost;
