import React from 'react'
import { ColorRing } from 'react-loader-spinner'

function Loading() {
  return (
    <div style={{position:'fixed',zIndex:'10',top:'0',right:'0',
        height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'
        ,background:'white',width:'100%'
    }} className='Loading'>
      <ColorRing
  visible={true}
  height="80"
  width="80"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={['#000', '#000', '#000', '#000', '#000']}
  />
    </div>
  )
}

export default Loading
