import React, { useEffect, useRef, useState } from 'react'
import './css/Checkout.css'
import Header from './Header'
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

function Checkout({setSidibarCart,inpValue,setValue,post}) {

  const [isVisible, setIsVisible] = useState(true); // للتحكم في ظهور العنصر
  const soldPubRef = useRef(null); // مرجع للـ SoldPub

  // دالة لإخفاء الـ SoldPub عند النقر خارجها
  const handleClickOutside = (event) => {
    if (soldPubRef.current && !soldPubRef.current.contains(event.target)) {
      setIsVisible(false);
      setSidibarCart(false)
       // إخفاء الـ SoldPub
    }
  };

  useEffect(()=>{
    const inp = document.getElementById('inp2')
    inp.focus()
  },[post])

  useEffect(() => {
    // إضافة حدث النقر عند تحميل المكون
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // تنظيف الحدث عند إزالة المكون
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isVisible) return null; // إذا كان العنصر غير مرئي، لا تعرضه
  
  
  const subtotal = post?.price * inpValue; // Example value
  const taxes = 0.00; // Example value
  const total = subtotal + taxes;
  const priceSecondary = post?.priceSecondary * inpValue

  
  
  
  return (
    <div ref={soldPubRef} id='my-prop' className='Checkout'>
     <div className="Container">
     <div className="Checkout-container-1">
        <h2 className='item'>Your cart : 1 item</h2>
        <div className="Checkout-card">
            <div className="Product-cart">
              <img src={post?.images[0]?.imagePrincipale?.url} alt="" />
              <div className="Product-cart-title">
              <h2>{post?.title?.length > 12 
                  ? `${post.title.substring(0, 10)}...` 
                  : post.title} </h2>
              <p>
                {post?.description?.length > 24 
                  ? `${post.description.substring(0, 12)}...` 
                  : post.description}
              </p>              
            </div>
            </div>
            <div className="input-cart">
              <input id='inp2' onChange={(e)=>setValue(e.target.value)} value={inpValue} type="number" />
            </div>
            <div className="price">
              <h3> {subtotal} $ <span> {priceSecondary} $ </span> </h3>
            </div>
        </div>
      </div>
      <div className="Checkout-container-2 mt-5">
      <div className="card p-4">
        <h2 style={{fontSize:'20px'}} className="text-center mb-4">Order Summary</h2>
        <div className="row mb-3">
          <div style={{fontSize:'15px'}} className="col-6">Subtotal (1 items):</div>
          <div className="col-6 text-right">${subtotal.toFixed(2)}</div>
        </div>
        <div className="row mb-3">
          <div style={{fontSize:'15px'}} className="col-6">Est. taxes & fees:</div>
          <div className="col-6 text-right">${taxes.toFixed(2)}</div>
        </div>
        <div className="row mb-3">
          <div className="col-6"><strong>Total (Digital Product):</strong></div>
          <div className="col-6 text-right"><strong>${total.toFixed(2)}</strong></div>
        </div>
        <div className="text-center mt-4">
        <Link to={'/add-to-cart/'+post?._id}>
        <button style={{fontSize:'18px'}} className="btn btn-primary btn-lg w-100">Check Out</button>
        </Link>
        </div>
        <p className="text-center text-success mt-3">
          Congrats — you get <strong>free delivery</strong> (digital product)!
        </p>
      </div>
      </div>
     </div>
        <h2 onClick={()=>setSidibarCart(false)} className='arrow-checkout'><FaArrowAltCircleRight/> </h2>
    </div>
  )
}

export default Checkout
