import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactGA from 'react-ga4'; // استيراد حزمة Google Analytics
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

// استبدل 'G-KZBJV8ZX1B' بـ Measurement ID الخاص بك
ReactGA.initialize('G-KZBJV8ZX1B');

// تتبع الصفحة عند التحميل
ReactGA.send('pageview');

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// إذا كنت تريد البدء في قياس الأداء في تطبيقك، مرر دالة
// لتسجيل النتائج (على سبيل المثال: reportWebVitals(console.log))
// أو أرسل إلى نقطة نهاية التحليلات. تعرف على المزيد: https://bit.ly/CRA-vitals
