import React, { useRef, useEffect, useState } from 'react';
import './css/product.css';
import { useNavigate } from 'react-router-dom';

function SoldPub({setSidibarCart,setsidibarSold}) {
  
  const navigate = useNavigate()
  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.querySelector('.soldPub');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  return (
    <div  onClick={()=>setsidibarSold(true)}  className='soldPub' style={{cursor:'pointer', background: 'rgb(247, 247, 247)' }}>
      <div style={{ gap: '0px' }} className="Product-container-rigth-sold ">
          <img  style={{ height: '45px', width: '45px' }} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729811870/ycavyaqax39mzrbxsgaw.jpg" alt="" />
          <h6>+</h6>
          <img  style={{ height: '45px', width: '45px' }} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813849/nze6ixmx6rffz7ilhx6a.jpg" alt="" />
          <div className="Product-container-rigth-sold-free">
          <h6>+</h6>
          <img  style={{ height: '45px', width: '45px' }} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813431/r0usp70or16wty6ls729.jpg" alt="" />
          <h4 className='bg-success'>free</h4>
        </div>
        <h6>=</h6>
        <div className="Product-container-rigth-sold-btn">
          <h5 style={{ fontSize: '12px' }}>59$ <span style={{ fontSize: '8px' }}> 1097$ </span></h5>
          <button  style={{ fontSize: "8px", fontWeight: '700' }}> Add all 2 to Cart </button>
        </div>
      </div>
    </div>
  );
}

export default SoldPub;
