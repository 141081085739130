import React, { useEffect, useRef, useState } from 'react'
import './css/Video.css'
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowAltCircleRight } from "react-icons/fa";

function Video({vidoeurl,setVideo}) {


  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.querySelector('.Video-page');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  

  return (
    <div className='Video-page'>
        <div className="Videop-container">
        <video  src={vidoeurl} controlsList="nodownload" controls></video>
        <h2 onClick={()=>setVideo(false)} ><IoMdCloseCircle/></h2>
        <h3 onClick={()=>setVideo(false)} ><FaArrowAltCircleRight/></h3>
        </div>
    </div>
  )
}

export default Video
