import React, { useEffect, useState } from 'react'
import './css/page3.css'
import { FaStar } from "react-icons/fa";
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
function Page3({posts}) {

    /*${process.env.REACT_APP_API_URL}/ */
    const latestPostDate = posts[0]?.createdAt; // تاريخ أحدث بوست

    const navigate = useNavigate()
    useEffect(() => {
      // العثور على العنصر المحدد بالـ class "Product"
      const productDiv = document.querySelector('.Page3');
  
      // منع القائمة التي تظهر عند الضغط على الزر الأيمن
      const handleContextMenu = (event) => {
        event.preventDefault(); // منع السلوك الافتراضي
      };
  
      // إضافة الحدث "contextmenu"
      if (productDiv) {
        productDiv.addEventListener('contextmenu', handleContextMenu);
      }
  
      // تنظيف الحدث عند إلغاء التثبيت
      return () => {
        if (productDiv) {
          productDiv.removeEventListener('contextmenu', handleContextMenu);
        }
      };
    }, []);
  
  return (
    <div className="Page3">
        <h2>Related product</h2>
        <div className="page3-products">
            {posts.map(post =>{
                const isNew = post.createdAt === latestPostDate; // تحقق مما إذا كان هذا هو الأحدث
                return (
                    <div onClick={()=>navigate('/post/'+post?._id)}  key={post?._id} className="page3-product">
            {isNew && <h3 className='bg-success'>new</h3>} {/* عرض "new" فقط إذا كان الأحدث */}
            <img src={post?.images.map(img =>{
                    return img.imagePrincipale.url
        })} alt="" />
                <div className="page3-product-text">
                    <h6> {post?.category?.length > 12 
                  ? `${post.category.substring(0, 8)}...` 
                  : post.category}  <span><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></span>({post?.sales} ) </h6>
                    <h5>{post?.title?.length > 12 
                  ? `${post.title.substring(0, 18)}...` 
                  : post.title} </h5>
                    <h4>{post?.price} $ <span>{post?.priceSecondary} $</span></h4>
                </div>
            </div>
                )
            })}
           
        </div>
        <hr />
    </div>
  )
}

export default Page3
