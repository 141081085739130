import React, { useEffect, useState } from 'react'
import './css/page4.css'
import axios from 'axios'
import { ColorRing } from 'react-loader-spinner'

import { ToastContainer, toast } from 'react-toastify'
function Page4() {

  const [username,setUsername] = useState('')
  const [email,setemail] = useState('')
  const [text,settext] = useState('')
  const [loading,setLoading] = useState(false)
  const sendMessage = async(e)=>{
    e.preventDefault()
    setLoading(true)
    if(username.length < 3){
      toast.error('Please enter your username')
      setLoading(false)
      return
    }
    if(!/^\S+@\S+\.\S+$/i.test(email)){
      toast.error('Please enter a valid email')
      setLoading(false)
      return
    }
    if(text.length < 6){
      toast.error('Please enter a detailed request')
      setLoading(false)
      return
    }
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v2002/send-message`,{
      username,
      email,
      text
    }).then((res)=>{
      toast.success(res.data.message)
      setUsername("")
      setemail("")
      settext("")
      setLoading(false)
    })
  }


  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.querySelector('.page4');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  return (
    <div className='page4 col-10'>
      <ToastContainer/>
       <div className="page4-img ">
          <img src="http://res.cloudinary.com/dvivzto6g/image/upload/v1729894133/naqmyrlfjfsggwnh2wda.png" alt="" />
       </div>
       <div className="page4-text">
          <h2>Need support?</h2>
          <p>Contact us if you need furtter assistance.</p>
        <form action="">
          <h3>Name and username</h3>
          <input value={username} onChange={(e)=>setUsername(e.target.value)} type="text" id="" />
          <h3>Email</h3>
          <input  value={email} onChange={(e)=>setemail(e.target.value)}type="email"   />
          <h3>Please enter your details of your request</h3>
          <textarea value={text} onChange={(e)=>settext(e.target.value)} name="" id=""></textarea>
          <button onClick={sendMessage} className='btn btn-danger'>{loading ? <ColorRing
  visible={true}
  height="40"
  width="40"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={['#fefe', '#fefe', '#fefe', '#fefe', '#fefe']}
  /> : "Send"} </button>
        </form>
       </div>
    </div>
  )
}

export default Page4
