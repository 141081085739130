import React from 'react'
import Header from './component/Header'
import Product from './component/Product'
function Page1({setsidibarSold, imgSrc,setImgSrc, setValue,inpValue,setSidibarCart,postId,setvideo,post,serBarEmail,setUrlfile,setVideourl}) {
  return (
    <div className='page1 col-12'>
      <Product setsidibarSold={setsidibarSold} imgSrc={imgSrc} setImgSrc={setImgSrc} setValue={setValue} setUrlfile={setUrlfile} setVideourl={setVideourl} serBarEmail={serBarEmail}  inpValue={inpValue} post={post} setvideo={setvideo} postId={postId} setSidibarCart={setSidibarCart} />
    </div>
  )
}

export default Page1
