import React, { useEffect, useRef, useState } from 'react'
import './css/Checkout.css'
import Header from './Header'
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';

function CheckoutSold({setSidibarCart}) {
    const navigate = useNavigate()

  const [isVisible, setIsVisible] = useState(true); // للتحكم في ظهور العنصر
  const soldPubRef = useRef(null); // مرجع للـ SoldPub
  const[inpValue,setValue] = useState(1)
  // دالة لإخفاء الـ SoldPub عند النقر خارجها
  const handleClickOutside = (event) => {
    if (soldPubRef.current && !soldPubRef.current.contains(event.target)) {
      setIsVisible(false);
      setSidibarCart(false)
       // إخفاء الـ SoldPub
    }
  };



  useEffect(() => {
    // إضافة حدث النقر عند تحميل المكون
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // تنظيف الحدث عند إزالة المكون
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isVisible) return null; // إذا كان العنصر غير مرئي، لا تعرضه
  
  
 // tabalue 1 
  const priceTab1 = 29
  const priceTab2Secondqry = 399
  const title = "title tile titile"
  const description = "description description description"
// tablaux 2
  const priceTab2 = 29
  const priceTab2Second = 399
  const titleTab2 = "title tile titile"
  const descriptionTab2 = "description description description"
// tableux 3
  const priceTab3 = 0
  const priceTab3Second = 299
  const titleTab3 = "title tile titile"
  const descriptionTab3 = "description description description"
// totale
 const total =priceTab1 +priceTab2 +priceTab3 
 const subtotal =priceTab1 +priceTab2 +priceTab3 


  return (
    <div ref={soldPubRef} id='my-prop' className='Checkout'>
     <div className="Container">
     <div className="Checkout-container-1">
        <h2 className='item'>Your cart : 3 item</h2>
        <div className="Checkout-card">
            <div className="Product-cart">
              <img onClick={()=>navigate('/post/'+'671ad59824f46a250192b54b')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729811870/ycavyaqax39mzrbxsgaw.jpg" alt="" />
              <div className="Product-cart-title">
              <h2> A Glance t... </h2>
              <p>
              This uniq...
              </p>              
            </div>
            </div>
            <div className="input-cart">
                <h6>1</h6>
            </div>
            <div className="price">
              <h3> {priceTab1} $ <span> {priceTab2Secondqry} $ </span> </h3>
            </div>
        </div>
        <div className="Checkout-card">
            <div className="Product-cart">
              <img onClick={()=>navigate('/post/'+'671add4d7a04e5cac4136a71')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813849/nze6ixmx6rffz7ilhx6a.jpg" alt="" />
              <div className="Product-cart-title">
              <h2> Spectrum... </h2>
              <p>
              Spectru... </p>              
            </div>
            </div>
            <div className="input-cart">
                <h6>1</h6>
            </div>
            <div className="price">
              <h3> {priceTab2} $ <span> {priceTab2Second} $ </span> </h3>
            </div>
        </div>
        <div className="Checkout-card">
            <div className="Product-cart">
              <img onClick={()=>navigate('/post/'+'671adba47a04e5cac4136a5a')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813431/r0usp70or16wty6ls729.jpg" alt="" />
              <div className="Product-cart-title">
              <h2> The Mi...</h2>
              <p>
              The Mirror...
              </p>              
            </div>
            </div>
            <div className="input-cart">
                <h6>1</h6>
            </div>
            <div className="price">
              <h3 className='text-success'> free $ <span > {priceTab3Second} $ </span> </h3>
            </div>
        </div>
      </div>
      <div className="Checkout-container-2 mt-5">
      <div className="card p-4">
        <h2 style={{fontSize:'20px'}} className="text-center mb-4">Order Summary</h2>
        <div className="row mb-3">
          <div style={{fontSize:'15px'}} className="col-6">Subtotal (3 items):</div>
          <div className="col-6 text-right">${subtotal.toFixed(2)}</div>
        </div>
        <div className="row mb-3">
          <div style={{fontSize:'15px'}} className="col-6">Est. taxes & fees:</div>
          <div className="col-6 text-right">0</div>
        </div>
        <div className="row mb-3">
          <div className="col-6"><strong>Total (Digital Product):</strong></div>
          <div className="col-6 text-right"><strong>${total.toFixed(2)}</strong></div>
        </div>
        <div className="text-center mt-4">
        <Link to={'/add-to-cart-sold'}>
        <button  style={{fontSize:'18px'}} className="btn btn-primary btn-lg w-100">Check Out</button>
        </Link>
        </div>
        <p className="text-center text-success mt-3">
          Congrats — you get <strong>free delivery</strong> (digital product)!
        </p>
      </div>
      </div>
     </div>
        <h2 onClick={()=>setSidibarCart(false)} className='arrow-checkout'><FaArrowAltCircleRight/> </h2>
    </div>
  )
}

export default CheckoutSold
