import React, { useEffect } from 'react';
import './css/page5.css';
import Header from './component/Header';
import Footer from './component/footer';

const About = () => {

    useEffect(()=>{},[
        window.scroll(0,0)
      ],[])

      useEffect(() => {
        // العثور على العنصر المحدد بالـ class "Product"
        const productDiv = document.querySelector('.about-page');
    
        // منع القائمة التي تظهر عند الضغط على الزر الأيمن
        const handleContextMenu = (event) => {
          event.preventDefault(); // منع السلوك الافتراضي
        };
    
        // إضافة الحدث "contextmenu"
        if (productDiv) {
          productDiv.addEventListener('contextmenu', handleContextMenu);
        }
    
        // تنظيف الحدث عند إلغاء التثبيت
        return () => {
          if (productDiv) {
            productDiv.removeEventListener('contextmenu', handleContextMenu);
          }
        };
      }, []);

  return (
    <>
      <Header/>
    <div className="about-page container my-5">
      <section className="mb-5">
        <h2>Welcome to Artilo.site</h2>
        <p>
          At Artilo.site, we specialize in offering exclusive digital artworks created by the internationally recognized artist, Soufiane Moutaouakil. Our platform is dedicated to providing high-quality digital paintings, backed by authenticity and transparency. We cater to art collectors, home decorators, and enthusiasts seeking unique and inspiring pieces.
        </p>
      </section>

      <section className="mb-5">
        <h2>Our Mission</h2>
        <p>
          Our mission is to empower art lovers and collectors by providing them access to exceptional digital artworks that enhance their spaces and resonate with their personal style. We believe in the transformative power of art and strive to create a welcoming environment where everyone can discover their next favorite piece.
        </p>
      </section>

      <section className="mb-5">
        <h2>What We Offer</h2>
        <ul className="list-group mb-4">
          <li className="list-group-item">High-resolution images, perfect for home or office décor.</li>
          <li className="list-group-item">Authenticity certificates and detailed artwork descriptions.</li>
          <li className="list-group-item">Immediate access to download digital art files.</li>
          <li className="list-group-item">Regularly updated collection featuring fresh and inspiring works.</li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Why Choose Artilo.site?</h2>
        <ul className="list-group mb-4">
          <li className="list-group-item">Exclusive, original digital paintings by Soufiane Moutaouakil.</li>
          <li className="list-group-item">A secure and trusted platform appreciated by global buyers.</li>
          <li className="list-group-item">Detailed artwork dossiers with high-resolution images.</li>
          <li className="list-group-item">New pieces added regularly, reflecting contemporary art trends.</li>
          <li className="list-group-item">Ideal for personal collections, home décor, and thoughtful gifts.</li>
        </ul>
      </section>

      <section className="mb-5">
        <h2>Key Themes in Digital Art</h2>
        <div className="row">
          {[
            "Digital art for home décor",
            "Original digital paintings available",
            "Quality digital art downloads",
            "Affordable art for collectors",
            "Exclusive pieces by Soufiane Moutaouakil",
            "High-resolution prints for immediate download",
            "Art gifts for digital art enthusiasts",
            "Trusted online marketplace for art",
            "A top platform for modern digital art",
            "Secure digital art shop",
            "Decorative digital art for homes",
            "The best digital art gallery online",
            "High-quality digital art files at great prices"
          ].map((keyword, index) => (
            <div className="col-md-6 mb-3" key={index}>
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">{keyword}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <footer className="text-center mt-5">
        <p>
        If you’re looking for Digital Art Paintings that stand out in quality, our site is the perfect destination. We offer High-Quality Digital Art Paintings that captivate and catch the eye. In our collection, you’ll find Digital Fine Art crafted to the highest standards, making each piece a Unique Digital Art Painting for your collection.

      Explore our Exclusive Digital Fine Art that combines authenticity with modernity. Discover Advanced Digital Art Paintings designed to suit various tastes and spaces.

      We take pride in providing Modern Digital Fine Art that reflects the latest trends and styles in the art world. Make your home or office more beautiful and distinctive with Distinguished Digital Art Paintings.

      Our pieces come with a quality guarantee, ensuring you get part of the Outstanding Digital Fine Art that grabs attention and enhances the aesthetics of any place.

      Visit us now to discover Exceptional Digital Art Paintings that add a unique touch to any environment.

      Anything else on your mind?
        </p>
        <p className="text-muted">
          At Artilo.site, we are committed to fostering a community of art lovers and providing a platform where creativity thrives. Our curated selection of digital artworks reflects our dedication to quality and authenticity. Explore the world of digital art with confidence, knowing you are supported by a platform that values trust and transparency.
        </p>
        
      </footer>
    </div>
    <Footer/>
    </>
  );
};

export default About;
