import React, { useEffect, useState } from 'react'
import './css/product.css'
import { FaStar } from "react-icons/fa";
import Checkout from './Checkout';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { RiVerifiedBadgeFill } from "react-icons/ri";

function Product({setUrlfile,serBarEmail,setVideourl, imgSrc,setImgSrc,setsidibarSold, inpValue,post,setSidibarCart,setValue,postId,setvideo}) {
  
    const navigate = useNavigate()

    useEffect(()=>{
        const inp = document.getElementById('inp')
        window.onload =()=>{
            inp.focus()
        }
    },[])


        useEffect(() => {
          // العثور على العنصر المحدد بالـ class "Product"
          const productDiv = document.querySelector('.Product');
      
          // منع القائمة التي تظهر عند الضغط على الزر الأيمن
          const handleContextMenu = (event) => {
            event.preventDefault(); // منع السلوك الافتراضي
          };
      
          // إضافة الحدث "contextmenu"
          if (productDiv) {
            productDiv.addEventListener('contextmenu', handleContextMenu);
          }
      
          // تنظيف الحدث عند إلغاء التثبيت
          return () => {
            if (productDiv) {
              productDiv.removeEventListener('contextmenu', handleContextMenu);
            }
          };
        }, []);
    
    useEffect(() => {
        const imageZoom = document.getElementById('img');

        const handleMouseMove = (e) => {
            const rect = imageZoom.getBoundingClientRect();
            const x = ((e.clientX - rect.left) / rect.width) * 100;
            const y = ((e.clientY - rect.top) / rect.height) * 100;
            
            imageZoom.style.setProperty('--zoom-x', `${x}%`);
            imageZoom.style.setProperty('--zoom-y', `${y}%`);
            imageZoom.style.setProperty('--display', 'block');
        };

        const handleMouseOut = () => {
            imageZoom.style.setProperty('--display', 'none');
        };

        imageZoom.addEventListener('mousemove', handleMouseMove);
        imageZoom.addEventListener('mouseout', handleMouseOut);

        return () => {
            imageZoom.removeEventListener('mousemove', handleMouseMove);
            imageZoom.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    const OpenCart =(e)=>{
        e.preventDefault()
        setSidibarCart(true)
    }

   const requestFreeFile= ()=>{
    setUrlfile('https://docs.google.com/document/d/1lQPqpxxBDSq_Jmp3heQQoyShPdLlUGYLUD-FPv-sICE/edit?usp=sharing')
    serBarEmail(true)
   }

    const image1 = post?.images && post?.images[0] ? post?.images[0].imagePrincipale.url : null;
    const image2 = post?.images && post?.images[0] ? post?.images[0].imageSecondary1.url : null;
    const image3 = post?.images && post?.images[0] ? post?.images[0].imageSecondary2.url : null;
    const image4 = post?.images && post?.images[0] ? post?.images[0].imageSecondary3.url : null;
    const image5 = post?.images && post?.images[0] ? post?.images[0].imageSecondary4.url : null;
    const image6 = post?.images && post?.images[0] ? post?.images[0].imageSecondary5.url : null;
    const image7 = post?.images && post?.images[0] ? post?.images[0].imageSecondary6.url : null;
    
    return (

    <div className="Product col-10">
        <div className="Product-container">
            <div className="Product-container-left">
                <div id='img' style={{height:"auto",
        '--background-image': `url(${imgSrc})`,
      }} className="Product-container-img-div">
                    <img   src={imgSrc} alt="" />
                </div>
                <div className="Product-container-imges col-12">
                    <img onClick={()=>setImgSrc(image1)} src={image1} alt="" />
                    <img onClick={()=>setImgSrc(image2)} src={image2} alt="" />
                    <img onClick={()=>setImgSrc(image3)+setvideo(true)+setVideourl(post?.videoUrl)} src={image3} alt="" />
                    <img onClick={()=>setImgSrc(image4)} src={image4} alt="" />
                    <img onClick={()=>setImgSrc(image5)} src={image5} alt="" />
                    <img onClick={()=>setImgSrc(image6)} src={image6} alt="" />
                    <img onClick={()=>setImgSrc(image7)} src={image7} alt="" />
                </div>
            </div>
            <div className="Product-container-rigth">
                <h2>{post?.title} </h2>
                <h3>4.9<span><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></span>(Sales  {post?.sales ? post?.sales : 0} ) </h3>
                <h4>{post?.price} $ <span>{post?.priceSecondary} $</span></h4>
                <hr />
                <p>{post?.description} </p>
                
                <div className="Product-container-rigth-btn">
                    <form action="">
                    <input value={inpValue} onChange={(e)=>setValue(e.target.value)} id='inp' type="number" />
                    <button popovertarget="popover" onClick={OpenCart}   >Add to Cart</button>
                    <h3>({post?.theRest} copies remaining.)</h3>
                    </form>
                
                </div>
                <hr />
                <div className="Product-container-rigth-category">
                    <Link>
                    <a onClick={requestFreeFile}>Download the free file</a>
                    </Link>
                    <h5>Categories : <span>art , realism & {post?.category} </span></h5>
                    <h5>tag : <span>blouse</span></h5>
                    <hr />
                    <Link to={'/soufiane-moutaouakil'}>
                    <h5 className='soufiane-moutaouakil-h2 text-dark'>By : <span className='soufiane-moutaouakil text-primary'>Soufiane moutaouakil <RiVerifiedBadgeFill/> </span></h5>
                    </Link>
                </div>
                <hr />
                <div className="Product-container-rigth-sold">
                    <img onClick={()=>setImgSrc('https://res.cloudinary.com/dktyi1hnl/image/upload/v1729811870/ycavyaqax39mzrbxsgaw.jpg')+navigate('/post/671ad59824f46a250192b54b')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729811870/ycavyaqax39mzrbxsgaw.jpg" alt="" />
                    <h6>+</h6>
                    <img onClick={()=>setImgSrc('https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813849/nze6ixmx6rffz7ilhx6a.jpg')+navigate('/post/671add4d7a04e5cac4136a71')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813849/nze6ixmx6rffz7ilhx6a.jpg" alt="" />
                    <div className="Product-container-rigth-sold-free">
                    <h6>+</h6>
                    <img onClick={()=>setImgSrc('https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813431/r0usp70or16wty6ls729.jpg')+navigate('/post/671adba47a04e5cac4136a5a')} src="https://res.cloudinary.com/dktyi1hnl/image/upload/v1729813431/r0usp70or16wty6ls729.jpg" alt="" />
                    <h4 className='bg-success'>free</h4>
                    </div>
                    <h6>=</h6>
                    <div className="Product-container-rigth-sold-btn">
                            <h5>59$ <span> 1097$ </span></h5>
                            <button  onClick={()=>setsidibarSold(true)}>Add all 2 to Cart </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Product
